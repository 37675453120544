<template>
  <div class="login-layout">
    <img src="https://zapisy.kayotravel.pl/cms/frontImage.php" class="login-layout__image" alt="">
    <el-main>
      <slot />
    </el-main>
  </div>
</template>

<script setup>
</script>
